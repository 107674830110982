.contacts_list_container_info{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    
    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 770px) {
        display: flex;
        flex-direction: column;
    }
}
.contacts_list_map{
    grid-row: 1 / span 2;
    grid-column: 2 / span 2;
    min-height: 400px;

    @media (max-width: 1200px) {
        grid-row: 1 / span 2;
        grid-column: 1 / span 2;
    }
    
    @media (max-width: 770px) {
        min-height: 270px;
        height: 270px;
    }
}